import React from "react";
import { Upload, message } from "antd";
import { s3Client } from "../../sdk/s3";

class S3Upload extends React.Component {
  state = {
    fileList: [],
  };

  componentDidMount() {
    this.updateFileList(this.props.value);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.updateFileList(this.props.value);
    }
  }

  updateFileList = (value) => {
    if (!value) {
      this.setState({ fileList: [] });
      return;
    }

    const fileList = Array.isArray(value) ? value : [value];
    const formattedFileList = fileList.map(file => ({
      uid: file.uid || `rc-upload-${Date.now()}`,
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      percent: 100,
      status: 'done',
      url: file.url,
      response: {
        url: file.url,
      },
      originFileObj: file,
    }));

    this.setState({ fileList: formattedFileList });
  };

  handleUpload = async ({ file, onProgress, onSuccess, onError }) => {
    const key = `${Date.now()}-${file.name}`;
    try {
      const result = await s3Client.upload(key, file, {
        onProgress: progress => {
          onProgress({ percent: (progress.loaded / progress.total) * 100 });
        },
      });

      const fileInfo = {
        uid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        percent: 100,
        status: "done",
        response: {
          url: result.url,
          key: key,
        },
        url: result.url,
        originFileObj: file,
      };

      onSuccess(fileInfo);
    } catch (err) {
      onError(err);
      message.error("上传失败");
    }
  };

  onRemove = (file) => {
    try {
      const { fileList } = this.state;
      const newFileList = fileList.filter(item => item.uid !== file.uid);
      this.setState({ fileList: newFileList });

      if (this.props.onChange) {
        const value = this.props.multiple 
          ? newFileList 
          : newFileList[0];
        
        this.props.onChange(value);
      }

      return true;
    } catch (err) {
      message.error("删除文件失败");
      return false;
    }
  };

  onChange = ({ file, fileList }) => {
    // 处理文件状态变化
    const newFileList = fileList.map(f => {
      if (f.uid === file.uid) {
        return { ...f, status: file.status, percent: file.percent };
      }
      return f;
    });

    this.setState({ fileList: newFileList });

    // 只在文件上传完成或删除时通知父组件
    if (file.status === 'done' || file.status === 'removed') {
      if (this.props.onChange) {
        const simplifiedFileList = newFileList.map(f => ({
          uid: f.uid,
          name: f.name,
          size: f.size,
          type: f.type,
          lastModified: f.lastModified,
          lastModifiedDate: f.lastModifiedDate,
          percent: f.percent || 100,
          status: f.status,
          url: f.response?.url || f.url,
          response: f.response,
          originFileObj: f.originFileObj,
        }));

        const value = this.props.multiple 
          ? simplifiedFileList 
          : simplifiedFileList[0];
        
        this.props.onChange(value);
      }
    }
  };

  render() {
    const { children, onChange, value, multiple = false, ...restProps } = this.props;
    const { fileList } = this.state;

    return (
      <Upload
        customRequest={this.handleUpload}
        onChange={this.onChange}
        onRemove={this.onRemove}
        fileList={fileList}
        multiple={multiple}
        {...restProps}
      >
        {/* 非多文件上传时，只有在没有文件的情况下显示上传按钮 */}
        {(!multiple && fileList.length > 0) ? null : children}
      </Upload>
    );
  }
}

export default S3Upload;